<template>
    <div class="form-settings">
        <EditForm :form-id="formId"/>
    </div>
</template>

<script>
import EditForm from "@/components/forms/EditForm";

export default {
    name: "FormSettings",
    components: {EditForm},
    data() {
        return {
            formId: this.$route.params.id,
        }
    },
    watch: {
        "$route.params.id"(val) {
            this.formId = val;
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.form-settings {
	display: block;
	padding: 1.5rem 1.5rem;
    flex-grow: 1;
    min-height: calc(100svh - 44px - 2rem);

    @include smartphone {
        padding: 0.75rem 0.75rem 0 0.75rem;
    }

	form {
		&.loading {
			position: relative;

			&:before {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				width: 25px;
				height: 25px;
				border-radius: 50%;
				border: 3px solid $border-darker-grey;
				border-right-color: transparent;
				content: ' ';
				margin: auto;
				animation: spinner-border 0.75s linear infinite;
				z-index: 2;
			}
		}

		.form-group {
			position: relative;

			label {
				display: block;
				font-size: 1rem;
				font-weight: 600;
				margin-bottom: 3px;
				color: $dark;
			}

			.form-control {
				padding: 15px 10px;
				height: auto;
				border: 1px solid $border-darker-grey;

				&:focus {
					box-shadow: none;
					border-color: $primary;
				}
			}
		}

	}
}
</style>