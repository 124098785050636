<template>
	<div class="form-integration">
        <div class="row">
            <div class="col-md-8 mx-auto text-center">
                <h2>Connect Your Form</h2>
                <p>Complete your form setup to start receiving submissions and notifications</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 mx-auto mt-4">

                <!-- Endpoint -->
                <CopyField label="Form Endpoint" button="Copy link" :content="formLink"></CopyField>

                <p class="text-center">Set your form‘s <span>action</span> attribute the above value</p>
                <p class="text-center">Make sure all your form elements have a <span>name</span> attribute</p>

                <hr class="my-5">

                <!-- Example Code -->
                <CodeField label="Example Form" v-if="formLink" :code="formExample" type="HTML"></CodeField>

            </div>
        </div>
	</div>
</template>

<script>
import CopyField from "@/components/widgets/CopyField";
import CodeField from "@/components/widgets/CodeField";

export default {
    name: "ProjectForms",
    components: {CodeField, CopyField},
    props: ['secret', 'type'],
    data() {
        return {
            loaded: false,
        }
    },
    created() {
        // If different form type, redirect to FormBuilder page
        if(this.type !== 1) {
            this.$router.replace("/forms/" + this.secret + "/builder");
        }
    },
    computed: {
        formLink() {
            if(!this.secret) return "";
            return process.env.VUE_APP_SUBMISSIONS_ENDPOINT + this.secret;
        },
        formExample() {
            return '<form action="' + this.formLink + '" method="POST">\n' +
                '  <label for="name">Your Name</label>\n' +
                '  <input type="text" name="name" id="name" required>\n' +
                '  <label for="email">Your Email</label>\n' +
                '  <input type="email" name="email" id="email" required>\n' +
                '  <label for="phone">Your Phone</label>\n' +
                '  <input type="text" name="phone" id="phone">\n' +
                '  <button type="submit">Submit form</button>\n' +
                '</form>';
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.form-integration {
    background: $white;
    padding: 5rem 1.5rem;
    height: 100%;
    flex-grow: 1;

    h2 {
        font-weight: bold;
        color: $dark;
        font-size: 2.5rem;
    }

	.form-loading {
		.spinner-border {
			width: 25px;
			height: 25px;
			border-radius: 50%;
			border: 3px solid $border-darker-grey;
			border-right-color: transparent;
			margin: 50px auto auto auto;
			display: block;
		}
	}

	p {
		& + p {
			margin-top: -10px;
		}

		&.small {
			color: $grey-text;
		}

		span {
			background: rgba($dark, 0.1);
			color: $primary;
			padding: 2px 6px;
			font-size: 0.85rem;
			font-weight: 500;
			border-radius: $box-border-radius;
		}
	}
}
</style>