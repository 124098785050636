<template>
	<div class="project-boxes">

        <!-- Title -->
		<ExplanationTitle heading="Your Forms" explanation="You can accept submissions on your project through multiple forms."></ExplanationTitle>

        <div :class="{'forms-breakdown-box': true, 'loading': !forms.loaded}">

            <!-- Forms -->
            <div class="form-breakdown" v-for="(form, key) in forms.items" :key="key">
                <router-link
                    :to="'/forms/' + form.hashId"
                    class="form-color"
                    :style="{'background-color': form.color.color}">
                </router-link>

                <router-link :to="'/forms/' + form.hashId" class="form-name">
                    {{ form.name }}
                </router-link>

                <div class="form-progress" v-if="form.total_submissions > 0">
                    <div class="progress">
                        <div class="progress-bar" :style="{'background-color': form.color.color, 'width': calculateFormPercentage(form) + '%'}"></div>
                    </div>
                    <span>{{ form.total_submissions }}</span>
                </div>

                <router-link :to="'/forms/' + form.hashId + '/integration'" class="btn btn-primary btn-sm ml-auto" v-if="form.total_submissions === 0">Connect</router-link>
            </div>

            <!-- New Form Button -->
            <button type="button" class="btn btn-link mt-3" @click.prevent="$root.$emit('openNewFormPopup')" v-if="forms.items.length <= 4">+ Create New Form</button>
        </div>
	</div>
</template>

<script>
import ExplanationTitle from "../widgets/ExplanationTitle";

export default {
    name: "FormBoxes",
    components: {ExplanationTitle},
    props: ['submissions', 'loaded'],
    methods: {
        calculateFormPercentage(form) {
            if(!this.loaded) return 0;
            if(this.submissions.total === 0) return 0;
            if(form.total_submissions === 0) return 0;
            return ((100 / this.submissions.total) * form.total_submissions).toFixed(1);
        },
    },
    computed: {
        forms() {
            return this.$store.getters.forms;
        },
        currentProject() {
            return this.$store.getters.currentProject;
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.project-boxes {
    display: flex;
    flex-direction: column;
    height: 100%;

    @include smartphone {
        height: auto;
        margin-bottom: 1rem;
    }
}

.forms-breakdown-box {
    display: block;
    background: $white;
    border-radius: $box-border-radius;
    position: relative;
    text-decoration: none;
    height: 100%;
    @extend .animated;

    @include smartphone {
        margin-bottom: 15px;
        height: auto;
        padding: 0;
        box-shadow: none !important;
        overflow: visible;
    }

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $white;
        z-index: 1;
        content: ' ';
        opacity: 0;
        visibility: hidden;
        transition: 0.15s ease all;
    }

    &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 25px;
        height: 25px;
        border: 2px solid $border-darker-grey;
        border-right-color: transparent;
        content: ' ';
        z-index: 2;
        border-radius: 50%;
        margin: auto;
        animation: spinner-border 0.75s linear infinite;
        opacity: 0;
        visibility: hidden;
        transition: 0.15s ease all;
    }

    .form-breakdown {
        display: flex;
        align-items: center;
        padding: 0.5rem;
        width: calc(100% + 1rem);
        margin: 0 -0.5rem;
        border-radius: $box-border-radius;
        @extend .animated;

        &:hover {
            background: $background-grey;

            .form-progress {
                .progress {
                    background: $white;
                }
            }
        }

        .form-color {
            width: 1.5rem;
            height: 1.5rem;
            border-radius: $box-border-radius;
            background: $background-grey;
            margin-right: 0.5rem;
        }

        .form-name {
            width: 55%;
            flex-shrink: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 1rem;
            color: rgba($dark, 0.75);
            padding-right: 1.5rem;
        }

        .form-progress {
            margin-left: auto;
            width: calc(45% - 2rem);
            display: flex;
            align-items: center;

            @include smartphone {
                width: calc(35% - 2rem);
            }

            .progress {
                background: $background-grey;
                border-radius: $box-border-radius;
                width: 100%;
                @extend .animated;

                .progress-bar {
                    border-radius: $box-border-radius;
                }
            }

            span {
                width: 3rem;
                flex-shrink: 0;
                text-align: right;
                font-size: 0.8rem;
                font-weight: 600;
                color: $dark;
            }
        }
    }

    &.loading {
        &:before,
        &:after {
            opacity: 1;
            visibility: visible;
            transition: none;
        }
    }
}

</style>