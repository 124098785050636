<template>
	<div class="page-wrapper">

        <!-- Project Stats -->
        <ProjectStats
            :submissions="submissions"
            :forms="forms"
            :daily="daily"
            :loaded="loaded">
        </ProjectStats>

        <hr class="mb-5">

        <!-- Submissions & Breakdown -->
        <div class="row mt-3 mt-xl-0 mb-5">
            <div class="col-xl-8">
                <!-- Last 14 days -->
                <ProjectDailyChart
                    :submissions="submissions"
                    :daily="daily"
                    :loaded="loaded"
                >
                </ProjectDailyChart>
            </div>
            <div class="col-xl-4">
                <!-- Forms -->
                <FormBoxes
                    :submissions="submissions"
                    :loaded="loaded"
                >
                </FormBoxes>
            </div>
        </div>

        <!-- Latest Submissions -->
        <ProjectSubmissions></ProjectSubmissions>


        <!-- Confirm Integration -->
<!--        <ConfirmIntegration :class="{'active': loaded && submissions.total === 0}" :forms="forms"/>-->

	</div>
</template>

<script>
import ProjectStats from "@/views/Projects/ProjectStats";
import repository from "@/repository/repository";
import FormBoxes from "@/components/models/FormBoxes.vue";
import ProjectDailyChart from "@/views/Projects/ProjectDailyChart.vue";
import ProjectSubmissions from "@/views/Projects/ProjectSubmissions.vue";

export default {
    name: 'Home',
    components: {
        ProjectSubmissions,
        ProjectDailyChart,
        FormBoxes,
        ProjectStats,
    },
    data() {
        return {
            submissions: {
                today: 0,
                week: 0,
                month: 0,
                total: 0,
            },
            daily: [],
            loaded: false,
        }
    },
    mounted() {
        // Load statistics on page load
        this.fetchData(this.projectId);

        // Re-load the statistics when needed
        this.reloadStatsHandler = () => {
            this.fetchData(this.projectId);
        };

        this.$root.$on('reloadStats', this.reloadStatsHandler);
    },
    beforeDestroy() {
        // Remove the event listener when the component is destroyed
        this.$root.$off('reloadStats', this.reloadStatsHandler);
    },
    methods: {
        fetchData(id) {
            this.loaded = false;
            if(!id) return;
            repository.get("/projects/" + id + "/statistics")
                .then(response => {
                    this.submissions = response.data.submissions;
                    this.daily = response.data.daily;
                    this.loaded = true;
                })
                .catch(error => {
                })
        },
    },
    computed: {
        projectId() {
            return this.$store.getters.currentProject.hashId;
        },
        forms() {
            return this.$store.getters.forms;
        }
    },
    watch: {
        "$store.getters.currentProject.hashId"(val) {
            this.fetchData(val);
        }
    },
}
</script>

<style lang="scss">
@import "src/scss/variables";

.page-wrapper {
	padding: 1.5rem;
	position: relative;
    min-height: 100%;

    @include smartphone {
        padding: 0.75rem 15px;
    }

    &.background-grey {
        background: $background-grey;

        @include smartphone {
            background: $white;
        }
    }

    .page-inside-wrapper {
        padding: 0 1.5rem;
        background: $background-grey;

        @include smartphone {
            padding: 0 0.75rem;
        }
    }

	h1 {
		font-weight: 600;
		font-size: 1.25rem;
		color: $dark;
		margin: 0 0 1rem 0;
	}
}
</style>
