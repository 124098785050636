<template>
	<div class="stats">

        <!-- Top Stats -->
		<div class="row align-items-center mb-3">
			<div class="col-6">
				<ExplanationTitle class="mb-0" heading="Project Statistics" explanation=""></ExplanationTitle>
			</div>
			<div class="col-6 text-right">
				<router-link v-if="loaded" to="/submissions" class="btn btn-link">View all submissions ></router-link>
			</div>
		</div>
		<div class="row md-smaller-margin">
			<div class="col-6 col-xl-3">
				<div :class="{'stats-box small-box': true, 'loading': !loaded}">
					<img src="@/assets/icons/submissions-today.svg" class="icon" alt="Total Submission">
					<strong>{{ submissions.today }}</strong>
					<span>Submissions Today</span>
				</div>
			</div>
			<div class="col-6 col-xl-3">
				<div :class="{'stats-box small-box': true, 'loading': !loaded}">
					<img src="@/assets/icons/submissions-week.svg" class="icon">
					<strong>{{ submissions.week }}</strong>
					<span>Last 7 days</span>
				</div>
			</div>
			<div class="col-6 col-xl-3">
				<div :class="{'stats-box small-box': true, 'loading': !loaded}">
					<img src="@/assets/icons/submissions-month.svg" class="icon">
					<strong>{{ submissions.month }}</strong>
					<span>Last 30 days</span>
				</div>
			</div>
			<div class="col-6 col-xl-3">
				<div :class="{'stats-box small-box': true, 'loading': !loaded}">
					<img src="@/assets/icons/submissions-total.svg" class="icon">
					<strong>{{ submissions.total }}</strong>
					<span>Total Submission</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ExplanationTitle from "../../components/widgets/ExplanationTitle";

export default {
    name: "ProjectStats",
    components: {ExplanationTitle,},
    props: ['projectName', 'submissions', 'daily', 'forms', 'loaded'],
    computed: {
        projectId() {
            return this.$store.getters.currentProject.hashId;
        },
    },
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.stats {
	.stats-box {
		display: block;
		padding: 0 20px 0 65px;
		background: $white;
		border-radius: $box-border-radius;
		position: relative;
		text-decoration: none;
		margin-bottom: 30px;
        overflow: hidden;

		&.small-box {
			height: calc(100% - 30px);

            @include small-desktop {
                height: calc(100% - 15px);
                margin-bottom: 15px;
            }

            @include tablet {
                height: calc(100% - 15px);
                margin-bottom: 15px;
            }
		}

		@include smartphone {
			margin-bottom: 15px;
            height: auto;
			padding: 10px 0 0 0;
            box-shadow: none !important;

			&.small-box {
				height: auto;
			}
		}

		&.chart-box {
            padding: 0;

			@include smartphone {
				padding: 0;
			}
		}

		@extend .animated;

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: $white;
			z-index: 1;
			content: ' ';
			opacity: 0;
			visibility: hidden;
			transition: 0.15s ease all;
		}

		&:after {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			width: 25px;
			height: 25px;
			border: 2px solid $border-darker-grey;
			border-right-color: transparent;
			content: ' ';
			z-index: 2;
			border-radius: 50%;
			margin: auto;
			animation: spinner-border 0.75s linear infinite;
			opacity: 0;
			visibility: hidden;
			transition: 0.15s ease all;
		}

		&.loading {
			&:before,
			&:after {
				opacity: 1;
				visibility: visible;
				transition: none;
			}
		}

		img.icon {
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto;
			left: 0;
			width: 40px;
			height: 40px;
			user-select: none;
			pointer-events: none;

			@include smartphone {
				position: relative;
				top: 0;
				left: 0;
				margin: 0;
				width: 25px;
				height: 25px;
			}
		}

		strong {
			font-weight: 700;
			color: $dark;
			display: block;
			font-size: 2rem;
			margin-bottom: -5px;

			@include smartphone {
				font-size: 1.5rem;
			}
		}

		span {
			font-weight: 400;
			color: $dark;
			display: block;
			margin: 0;

			@include smartphone {
				font-size: 0.8rem;
			}
		}

		@include tablet {
			padding: 10px 10px 10px 50px;
		}
	}

	.line-chart-area {
		width: 100%;
	}
}
</style>

<style lang="scss">
@import "src/scss/variables";
.apexcharts-tooltip.apexcharts-theme-light {
    padding: 10px !important;
    border-radius: 1rem !important;
    box-shadow: rgba($dark, 0.1) 0 0.5rem 1.5rem !important;
    border: none !important;

	.apexcharts-tooltip-title {
		background: none !important;
		border: none !important;
		padding: 10px 10px 5px 10px !important;
		margin: 0 !important;
		font-weight: 600 !important;
		color: $primary !important;
        font-size: 1rem !important;
		font-family: 'Inter', sans-serif !important;
	}

	.apexcharts-tooltip-series-group {
		padding: 0 10px 5px 10px;
		display: flex !important;
        align-items: center;

		.apexcharts-tooltip-marker {
            display: block;
            border-radius: 0.25rem;
            margin-right: 5px;
		}

		.apexcharts-tooltip-text {
			display: block;
			width: 100%;
		}

		.apexcharts-tooltip-y-group {
			padding: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

			span {
				color: $dark;
				font-size: 0.9rem;
				font-family: 'Inter', sans-serif;
				display: inline-block;
				margin-right: 5px;

                &.apexcharts-tooltip-text-label {
                    color: rgba($dark, 0.5);
                }

                &.apexcharts-tooltip-text-value {
                    font-weight: bold;
                }
			}
		}
	}
}
</style>