<template>
    <div class="h-100">
        <div class="submissions-table-wrapper">

            <div class="row align-items-center mb-3">
                <div class="col-12 col-lg-4">
                    <!-- Submissions Type -->
                    <h1 class="mb-0">All Submissions</h1>
                </div>
                <div class="col-12 col-lg-8 mt-4 mt-lg-0">
                    <FormActions :form-name="formName"/>
                </div>
            </div>

            <!-- Submissions Table -->
            <div :class="{'submissions-table': true, 'empty-table': meta.totalSubmissions < 10, 'loading': !loaded}" ref="submissionsTable">

                <table class="table">
                    <thead>
                    <tr>
                        <th>Submission</th>
                        <th scope="col" v-for="(field) in formFields" :key="field">{{ capitalizeFirstLetter(field) }}</th>
                        <th>Submitted On</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(submission) in items" :key="submission.hashId" :class="{ 'new': submission.status !== 'seen', 'spam': submission.spam }">
                        <td>
                            <router-link :to="'/submissions/' + submission.hashId" class="submission-name" :title="submission.name">
                                <span :class="{'submission-avatar': true, 'text-light': submission.avatar.text === 'light', 'text-dark': submission.avatar.text === 'dark'}" :style="{backgroundColor: submission.avatar.color}">{{ submission.avatar.credentials }}</span>
                                <strong>{{ submission.name }}</strong>
                            </router-link>
                        </td>
                        <td v-for="(field) in formFields" :key="field" :title="submission.fields ? submission.fields[field] : ''">
                            {{ submission.fields ? submission.fields[field] : '' }}
                        </td>
                        <td class="submission-date">
                            {{ readableDate(submission.created_at) }}
                        </td>
                        <td>
                            <button type="button" :class="{'btn btn-outline-dark btn-sm': true, 'd-none': submission.spam}" @click.prevent="markAsSpam(submission)">Mark as Spam</button>
                            <button type="button" :class="{'btn btn-primary btn-sm': true, 'd-none': !submission.spam}" @click.prevent="markAsNotSpam(submission)">Mark as Not Spam</button>
                        </td>
                    </tr>

                    <!-- Loading Effect -->
                    <tr v-if="!loaded || meta.next" class="loading-tr">
                        <td>Loading effect entry</td>
                        <td>Loading effect</td>
                        <td>Loading</td>
                    </tr>
                    <tr v-if="!loaded || meta.next" class="loading-tr">
                        <td>Loading effect entry</td>
                        <td>Loading effect</td>
                        <td>Loading</td>
                    </tr>

                    <!-- No Submissions -->
                    <tr class="no-submissions" v-if="loaded && items.length === 0">
                        <td colspan="3" class="py-5 text-center">
                            <h4 class="font-weight-bold">No submissions</h4>
                            <p>You will view your submissions here upon receiving at least one submission.<br>If you haven't connected your form, please do so by clicking the button below.</p>
                            <router-link :to="'/forms/' + formId + '/integration'" class="btn btn-primary mt-2">Complete Form Integration</router-link>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <!-- Load more & total results -->
            <div class="submissions-results d-flex align-items-center pt-4 ">
                <!-- Total -->
                <p class="small m-0">Showing {{ items.length }} out of {{ meta.totalSubmissions }} submissions</p>

                <!-- Loading -->
                <span class="spinner-border mr-auto ml-3" v-if="meta.loading"></span>

                <!-- Filters -->
                <div class="submissions-filter">
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" type="checkbox" v-model="spam" id="spam">
                        <label class="custom-control-label" for="spam">Show spam submissions</label>
                    </div>

                    <div class="form-field">
                        <label for="per-page" class="form-label">Submissions per page</label>
                        <select v-model="perPage" id="per-page" class="form-control">
                            <option value="20" selected>20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="all">All</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormActions from "@/components/forms/FormActions.vue";
import {readableDate} from "@/helpers";
import repository from "@/repository/repository";

export default {
    name: "FormSubmissions",
    components: {FormActions},
    props: ['formName'],
    data() {
        return {
            items: [],
            loaded: false,
            perPage: 20,
            spam: false,
            meta: {
                next: null,
                totalSubmissions: null,
                loading: false,
            },
            submission: null,
            status: 'all',
        }
    },
    created() {
        // Load submissions
        this.loadSubmissions(this.status, this.perPage, true);
    },
    mounted() {
        // Handle scroll
        this.$refs.submissionsTable.addEventListener("scroll", this.handleScroll);

        // Re-load the submissions when needed
        this.reloadFormSubmissionsHandler = () => {
            this.loadSubmissions(this.status, this.perPage, true);
        };

        this.$root.$on('reloadFormSubmissions', this.reloadFormSubmissionsHandler);
    },
    beforeDestroy() {
        // Remove scroll listener
        this.$refs.submissionsTable.removeEventListener("scroll", this.handleScroll);

        // Remove the event listener when the component is destroyed
        this.$root.$off('reloadFormSubmissions', this.reloadFormSubmissionsHandler);
    },
    methods: {
        readableDate,
        loadSubmissions(status, perPage, clear = false, scroll = false) {
            if (clear) this.items = [];
            this.loaded = false;
            if(this.formId && !this.projectId) return;
            let submissionsEndpoint = (this.formId ? '/projects/' + this.projectId + '/forms/' + this.formId + '/submissions' : 'submissions');
            repository.get(submissionsEndpoint + "?status=" + status + "&perPage=" + perPage + (this.spam ? '&includeSpam=1' : ''))
                .then(response => {
                    this.items.push(...response.data.items);
                    this.meta.next = response.data.cursor;
                    this.meta.totalSubmissions = response.data.total;
                    this.loaded = true;
                    if(scroll) {
                        this.$nextTick(() => {
                            window.scrollTo(0, 1000);
                        });
                    }
                })
                .catch(() => {
                    // if (this.$route.path !== "/") this.$router.replace("/");
                })
        },
        loadMore() {
            if(this.formId && !this.projectId) return;
            if (this.meta.loading) return;
            this.meta.loading = true;
            let submissionsEndpoint = (this.formId ? '/projects/' + this.projectId + '/forms/' + this.formId + '/submissions' : 'submissions');
            repository.get(submissionsEndpoint + "?status=" + this.status + "&perPage=" + this.perPage + (this.spam ? '&includeSpam=1' : '') +  (this.formId ? "&form=" + this.formId : "") + (this.meta.next ? "&cursor=" + this.meta.next : ""))
                .then(response => {
                    this.meta.loading = false;
                    this.items.push(...response.data.items);
                    this.meta.next = response.data.cursor;
                    this.meta.totalSubmissions = response.data.total;
                })
                .catch(() => {
                })
        },
        handleScroll() {
            let submissionsTable = this.$refs.submissionsTable;
            if(this.meta.next && (submissionsTable.scrollTop + submissionsTable.clientHeight >= (submissionsTable.scrollHeight / 1.5))) {
                this.loadMore();
            }
        },
        deleteSubmission(id) {
            let deleteItem = this.items.filter(item => item.hashId === id)[0];
            if (!deleteItem) return;
            this.items.splice(this.items.indexOf(deleteItem), 1);
            this.$emit("updateDistribution");
            this.meta.totalSubmissions--;
        },
        updateSubmissionStatus(id, status) {
            this.items.filter(item => item.hashId === id)[0].status = status;
            this.$emit("updateDistribution");
        },
        capitalizeFirstLetter(str) {
            return str.toLowerCase().charAt(0).toUpperCase() + str.slice(1);
        },
        markAsSpam(submission) {
            submission.spam = 1;
            repository.post("/submissions/" + submission.hashId + "/spam");
        },
        markAsNotSpam(submission) {
            submission.spam = 0;
            repository.delete("/submissions/" + submission.hashId + "/spam");
        },
    },
    computed: {
        statusTitle() {
            if (this.status === null) return "";
            return this.status.split("-").map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(" ");
        },
        projectId() {
            return this.$store.getters.currentProject.hashId;
        },
        formId() {
            return this.$route.params.id;
        },
        formFields() {
            let fields = [];
            this.items.forEach(submission => {
                if(!submission.fields) return;
                Object.keys(submission.fields || {}).forEach(fieldKey => {
                    if(!fields.includes(fieldKey)) {
                        fields.push(fieldKey);
                    }
                })
            })
            return fields;
        }
    },
    watch: {
        status(newStatus) {
            this.loadSubmissions(newStatus, this.perPage, true);
        },
        spam() {
            this.loadSubmissions(this.status, this.perPage, true);
        },
        perPage(newPerPage) {
            this.loadSubmissions(this.status, newPerPage, true, true);
        },
        items(val) {
            if (this.loaded && val.length <= 0 && this.meta.next) {
                this.loadMore();
            }
        },
        formId() {
            this.loadSubmissions(this.status, this.perPage, true);
        },
        projectId: function (newValue, oldValue) {
            if(oldValue === null && newValue !== null && this.formId) {
                this.loadSubmissions(this.status, this.perPage, true);
            } else {
                if (this.$route.path !== "/") this.$router.replace("/");
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.submissions-table-wrapper {
    padding: 1.5rem;

    @include smartphone {
        padding: 0.75rem;
    }
}

.submissions-filter {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include smartphone {
        justify-content: flex-start;
        flex-direction: column;
    }

    .form-field {
        display: flex;
        align-items: center;
        margin-left: 2rem;

        .form-control {
            padding: 0.5rem;
            height: auto;
            margin-left: 0.5rem;
            width: auto;
            box-shadow: none;
            border: 1px solid $border-dark-grey;
        }

        .form-label {
            flex-shrink: 0;
            color: $dark;
            font-size: 0.9rem;
            margin: 0;
        }
    }

    .custom-control.custom-checkbox {
        padding-left: 0;
        font-size: 1rem;
        color: $grey-text;
        margin: 0;
        cursor: pointer;
        margin-bottom: -1px;
        @extend .disable-selection;

        .custom-control-label {
            padding: 0 0 0 2rem;
            font-weight: 400;
            color: $dark;
            cursor: pointer;
            display: block;
            width: 100%;
            flex-shrink: 0;
            font-size: 0.9rem;
            margin: 0;

            &:before {
                top: 0;
                bottom: 0;
                margin: auto;
                left: 0;
                width: 1.5rem;
                height: 1.5rem;
                border-color: $border-grey;
            }

            &:after {
                top: 0;
                bottom: 0;
                margin: auto;
                width: 1.5rem;
                height: 1.5rem;
                left: 0;
            }
        }

        .custom-control-input ~ .custom-control-label::before {
            border: 1px solid $border-dark-grey;
        }

        .custom-control-input:focus ~ .custom-control-label::before {
            box-shadow: rgba($primary, 0.15) 0 0 0 0.2rem;
        }

        .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
            background: rgba($primary, 0.15);
            border-color: rgba($primary, 0.15);
        }

        .custom-control-input:checked ~ .custom-control-label::before {
            background: $primary;
            border-color: $primary;
        }
    }
}

.submissions-table {
    width: calc(100% + 3rem);
    margin: 0 -1.5rem;
    padding: 0 1.5rem;
    overflow-y: scroll;
    overflow-x: scroll;
    height: calc(100svh - 11.7rem - 42px);

    &.empty-table {
        height: auto;
    }

    &.loading {
        overflow: hidden;

        .table {
            border-collapse: separate;
            border-spacing: 1rem;

            thead {
                box-shadow: none;

                tr {
                    th {
                        color: transparent;
                        background: rgba($background-grey, 0.85);
                        border-radius: $box-border-radius;
                        user-select: none;
                        pointer-events: none;

                        &:nth-child(n+2) {
                            background: none;
                            transform: none;
                        }
                    }
                }
            }

            tbody {
                tr {
                    background: none !important;
                    border: none;

                    &:hover {
                        background: none !important;
                    }

                    td {
                        color: transparent;
                        user-select: none;
                        pointer-events: none;
                    }

                    &.loading-tr {
                        width: 100%;

                        td {
                            color: transparent;
                            background: rgba($background-grey, 0.5);
                            border-radius: $box-border-radius;
                            user-select: none;
                            pointer-events: none;
                            transform: translateX(0.5rem);
                            height: 0.5rem;
                            padding: 0.5rem;
                        }
                    }
                }
            }

            .submission-name {
                color: inherit;

                .submission-avatar {
                    opacity: 0;
                }
            }
        }
    }

    &:not(.loading) {
        .table {
            tbody {
                tr.loading-tr {
                    background: $white;
                    border-top: 1rem solid $white;
                    border-bottom: 1rem solid $white;
                    transform: none;

                    td {
                        color: transparent;
                        background: none;
                        user-select: none;
                        pointer-events: none;
                        transform: translateX(1.5rem);
                        height: 0.5rem;
                        padding: 0.5rem;
                        position: relative;

                        &:after {
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background: red;
                            content: ' ';
                            z-index: 1;
                            background: rgba($background-grey, 0.5);
                            border-radius: $box-border-radius;
                        }

                        &:nth-child(2) {
                            transform: translateX(2.5rem);
                        }

                        &:nth-child(3) {
                            transform: translateX(3.5rem);
                        }
                    }
                }
            }
        }
    }

    @include smartphone {
        width: calc(100% + 1.5rem);
        margin: 0 -0.75rem;
        padding: 0 0.75rem;
    }

    .table {
        min-width: fit-content;
        margin: 0 -1.5rem;

        @include smartphone {
            margin: 0 -0.75rem;
        }

        thead {
            position: sticky;
            top: 0;
            background: $white;
            border-top: none;
            left: 0;
            box-shadow: rgba($dark, 0.05) 0 0.15rem 0.25rem;
            z-index: 2;

            tr {
                border-top: none;
                border-bottom: none;

                th {
                    border-top: none;
                    border-bottom: none;
                    font-size: 1rem;
                    font-weight: 600;
                    padding: 1rem;
                    width: 200px;
                    white-space: nowrap;

                    &:first-child {
                        padding-left: 1.5rem;

                        @include smartphone {
                            padding-left: 0.75rem;
                        }
                    }

                    &:last-child {
                        padding-right: 1.5rem;

                        @include smartphone {
                            padding-left: 0.75rem;
                        }
                    }
                }
            }
        }

        tbody {
            overflow-x: auto;
            border-top: none;

            tr {
                border-top: 1px solid rgba($dark, 0.05);
                @extend .animated;

                &.new {
                    background: lighten($primary, 52%);
                }

                &.spam {
                    background: $white;

                    &:hover {
                        background: none !important;
                    }

                    td:not(:last-child) {
                        opacity: 0.25;
                    }
                }

                &:hover {
                    background: lighten($background-grey, 0%) !important;
                }

                td {
                    padding: 1rem 1rem;
                    font-size: 0.9rem;
                    color: $dark;
                    border: none;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    vertical-align: middle;
                    word-wrap: break-word;
                    max-width: 250px;

                    &:first-child {
                        padding-left: 1.5rem;

                        @include smartphone {
                            padding-left: 0.75rem;
                        }
                    }

                    &:last-child {
                        padding-right: 1.5rem;

                        @include smartphone {
                            padding-left: 0.75rem;
                        }
                    }
                }
            }
        }

        .submission-name {
            display: flex;
            align-items: center;
            width: 180px;
            text-decoration: none;
            color: $dark;

            &:hover {
                color: $primary;

                .submission-avatar {
                    box-shadow: rgba($primary, 1) 0 0 0 0.15rem;
                }

                strong {
                    text-decoration: underline;
                }
            }

            .submission-avatar {
                width: 2.5rem;
                height: 2.5rem;
                flex-shrink: 0;
                border-radius: 50%;
                line-height: 2.5rem;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 0.8rem;
                text-align: center;
                margin-right: 0.5rem;
            }

            strong {
                font-weight: 600;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.submissions-results {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $border-grey;

    span.spinner-border {
        width: 24px;
        height: 24px;
        border-width: 2px;
        border-color: $border-darker-grey;
        border-right-color: transparent;
    }

    p.small {
        line-height: 21px;
    }
}
</style>